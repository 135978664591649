:root {
    --black: #00000086;
    --blue: #007bff;
    --brown: #5b4645e4;
    --dark-brown: #6a5250;
    --dark-gray: #9c9898e4;
    --gray: #a2a2a29e;
    --light-blue: #8080ff;
    --light-brown: #957665;
    --light-gray: #f7f7f7;
    --mid-gray: #ccc;
    --red: #b61d1d;
    --white: #fff;
}

.checkbox {
    display: none;
}

.checkbox:checked + label {
    background: var(--mid-gray);
    background-color: var(--mid-gray);
    opacity: 0.51;
}

.checkbox-container-item {
    align-content: baseline;
    align-items: center;
    display: grid;
    justify-content: center;
    justify-items: center;
    position: relative;
}

.checkbox-label {
    align-items: flex-end;
    box-shadow: inset 0px -20px 20px 3px var(--brown);
    color: var(--white);
    cursor: pointer;
    display: flex;
    font-size: larger;
    font-weight: 700;
    height: 98%;
    justify-content: center;
    margin-top: 5px;
    position: absolute;
    text-align: center;
    width: 98%;
}

.checkbox-label.checked {
    background: var(--mid-gray);
    opacity: 0.1;
}

.creative-icon {
    fill: var(--white);
}

.creative-icon:active {
    fill: var(--red);
}

.counter {
    align-items: center;
    background-color: var(--gray);
    border-radius: 30px;
    border: 2px solid var(--white);
    display: flex;
    margin-top: 10%;
    position: relative;
    width: 120px;
    z-index: 1002;
}

.counter-button {
    background-color: transparent;
    border: none;
    color: var(--black);
    cursor: pointer;
    flex: 1;
    font-size: 24px;
    outline: none;
}

.counter-value {
    background-color: var(--black);
    color: var(--white);
    font-size: 24px;
    text-align: center;
    width: 50px;
}

h2 {
    margin: 0%;
    padding: 0%;
}

input:checked + .slider {
    background-color: var(--light-brown);
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.pulse-button {
    animation: pulse 4s infinite;
    background-image: linear-gradient(
        90deg,
        var(--light-blue),
        var(--dark-brown)
    );
    background-size: 300% 100%;
    border-radius: 5px;
    border: 2px solid var(--light-gray);
    color: var(--white);
    cursor: pointer;
    font-size: 20px;
    margin-top: 5px;
    padding: 5px 20px;
    z-index: 1002;
}

.ReactModalPortal {
    position: relative;
    z-index: 1000;
}

.slider {
    background-color: var(--mid-gray);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

.slider:before {
    background-color: var(--white);
    bottom: 4px;
    content: '';
    height: 20px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 20px;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.style-modal {
    background-color: var(--dark-brown);
    border-radius: 10px;
    bottom: auto;
    height: 100%;
    right: auto;
    width: 100%;
}

.style-select-gallery {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    height: 90%;
}

.switch {
    display: inline-block;
    height: 28px;
    margin-right: 5px;
    position: relative;
    width: 54px;
}

.switch input {
    height: 0;
    opacity: 0;
    width: 0;
}

@keyframes pulse {
    0% {
        background-position: 100% 0%;
        transform: scale(1);
    }
    50% {
        background-position: 0% 100%;
        transform: scale(1.1);
    }
    100% {
        background-position: 100% 0%;
        transform: scale(1);
    }
}

@media (min-width: 768px) {
    .style-modal {
        border-radius: 10px;
        bottom: auto;
        height: 100%;
        right: auto;
        width: 100%;
    }
}

@media (orientation: landscape) {
    .checkbox-container {
        display: grid;
        grid-auto-columns: minmax(600px, 300px);
        grid-auto-flow: column;
        grid-template-rows: repeat(2, 1fr);
        height: 90%;
        left: 50%;
        overflow-x: auto;
        position: absolute;
        top: 55%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    .checkbox-container > div {
        background-color: var(--brown);
        box-sizing: border-box;
        width: 100%;
    }
}

@media ((orientation: landscape) and (max-width: 950px)) {
    .checkbox-container {
        grid-auto-columns: minmax(300px, 300px);
    }
}

@media (orientation: portrait) {
    .checkbox-container {
        bottom: 0;
        grid-auto-columns: minmax(200px, 200px);
        grid-auto-flow: row;
        grid-template-rows: repeat(auto-fill, minmax(200px, auto));
        height: 90%;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        width: 100%;
    }
    .checkbox-container > div {
        background-color: var(--brown);
        box-sizing: border-box;
        height: 30%;
        width: 100%;
    }
}
